import {Alert, AlertsSearchItem, Pager} from 'models';
import {Api, UrlParams} from './Api';
import { GlobalSearchApiParams } from './SearchApi';
import {AlertsFilterQueryReq} from 'models/Alert';

const URLS = {
  alerts: '/alerts',
  alertsCsv: '/alerts.csv',
  alert: '/alerts/:id',
}


export interface AlertSearchResults {
  alerts: AlertsSearchItem[],
  pager: Pager,
}


export interface EntityApiParams extends UrlParams {
  'entity_ids[]': number[]
  order: 'updated_at'
  sort_mode: 'desc'
}

export class AlertApi extends Api {
  async search(
    params: GlobalSearchApiParams | AlertsFilterQueryReq | EntityApiParams
  ): Promise<AlertSearchResults> {
    const alerts = (await this.get<AlertSearchResults>(URLS.alerts, params)).data
    return alerts
  }

  downloadSearchLink(params: GlobalSearchApiParams | AlertsFilterQueryReq | EntityApiParams): string {
    return this.requestUrl(URLS.alertsCsv, params)
  }

  async id(id: number): Promise<Alert> {
    const url = URLS.alert.replace(":id", id + "")
    const alert = (await this.get<{'alert': Alert}>(url)).data
    return alert.alert;
  }

  async searchCSV(
    params: GlobalSearchApiParams | AlertsFilterQueryReq | EntityApiParams
  ): Promise<string> {
    return (await this.get<string>(URLS.alertsCsv, {
      ...params,
      responseType: 'text'
    })).data
  }


}
