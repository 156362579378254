import {useAppContext} from 'context';
import {useEffect, useState} from 'react';
import {
  EntityRiskResult,
  ErrorDetail,
  SearchGroupOptionsT,
  TransactionFilterForm,
  TransactionsSearchItem
} from 'models';
import {buildQuery, buildQueryParams, EntityRiskTrackerApi, SearchApi, TransactionsApi} from 'apis';
import {LoadingComponent, PagerControl} from 'components';
import {TransactionTable} from 'apps/transactions/components/TransactionTable';
import {useHistory, useLocation} from 'react-router-dom';
import {countTranFilters, handleAsyncLoader, SessionStorage,} from 'lib';
import {qsToTranForm, tranRelatedToQueryReq, tranToQueryReq} from 'models/converters/TransactionConverters';
import {TransactionEntityTable} from 'apps/transactions/components/TransactionEntityTable';
import {
  SortBy,
  SortByType,
  TranListSubTab,
  TransactionListSubNav
} from 'apps/transactions/components/TransactionListSubNav';
import {TransactionFilterEditor} from 'apps/transactions/TransactionFilterEditor';
import {RelatedEntity} from 'models/RelatedEntity';
import {TranPaths} from 'apps/transactions/Transactions';
import {uniqueId} from 'lodash';


function buildPageUrl(subTab: TranListSubTab, params: TransactionFilterForm): string {
  const qs = buildQuery(params, true)
  switch (subTab) {
    case TranListSubTab.Related:
      return `${TranPaths.related}${qs}`;
    case TranListSubTab.Transactions:
      return `${TranPaths.list}${qs}`;
    default:
      // only used for paging, so not used by Filters
      throw new Error(`Unknown sub tab: ${subTab}`)
  }
}

export function TransactionList(
  {
    className,
    setPreviewTranId,
    previewTranId,
    setPreviewEntityId,
    previewEntityId,
    setPagerControl,
    filter,
  }: {
    className?: string,
    setPreviewTranId: (v: number | undefined) => void,
    previewTranId?: number,
    setPreviewEntityId: (v: number | undefined) => void,
    previewEntityId?: number,
    setPagerControl: (cntl: JSX.Element | undefined) => void,
    filter: TransactionFilterForm,
  }
): JSX.Element {

  const location = useLocation()
  const history = useHistory()

  const subTab = location.pathname === TranPaths.related
    ? TranListSubTab.Related
    : location.pathname === TranPaths.listFilters
      || location.pathname === TranPaths.relatedFilters
      ? TranListSubTab.Filters
      : TranListSubTab.Transactions


  useEffect(() => {
    SessionStorage.setCurrentTransactionsFilterView('list')
  }, [])

  useEffect(() => {
    setPreviewTranId(undefined)
    setPreviewEntityId(undefined)
  }, [subTab])

  const { session, user } = useAppContext()

  const [tranList, setTranList] = useState<TransactionsSearchItem[]>([])
  const [risks, setRisks] = useState<EntityRiskResult[]>([])
  const [entityList, setEntityList] = useState<RelatedEntity[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()


  function perPageLink(newSize: number): string {
    return buildPageUrl(subTab, {...filter, per_page: `${newSize}`, page: '1'});
  }

  function pageLink(newPage: number): string {
    return buildPageUrl(subTab, {...filter, page: `${newPage}`});
  }



  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {

      if (typeof risks === 'undefined')
        setRisks((await new EntityRiskTrackerApi(session.token).risks()))

      const params = tranToQueryReq(filter);

      switch (subTab) {
        case TranListSubTab.Transactions:
          const api = new TransactionsApi(session.token);
          const rsp = await api.search(params)

          setTranList(rsp.transactions)
          setEntityList([])

          setPagerControl(
            <PagerControl
              total={+rsp.pager.total_records}
              page={+rsp.pager.page}
              pageSize={+rsp.pager.per_page}
              setPageSize={pageSize => history.push(perPageLink(pageSize))}
              setPage={page => history.push(pageLink(page))}
              csvDownloadAllowed={user.csvDownloadAllowed}
              params={user.csvDownloadAllowed ? params : undefined}
              filenameCSV={'transactions'}
              searchCSV={api.searchCSV.bind(api)}
            />)

          break
        case TranListSubTab.Related:
          setTranList([])
          const rspEnt = await new SearchApi(session.token).entities(tranRelatedToQueryReq(filter))
          setEntityList(rspEnt.entities)

          setPagerControl(
            <PagerControl
              total={+rspEnt.pager.total_records}
              page={+rspEnt.pager.page}
              pageSize={+rspEnt.pager.per_page}
              setPageSize={pageSize => history.push(perPageLink(pageSize))}
              setPage={page => history.push(pageLink(page))}
            />)

          break
        case TranListSubTab.Filters:
          // do nothing
          break
        default:
          throw new Error('unknown type: ' + subTab)
      }
    }), [session.token, filter, subTab, user])


  const year = filter.year === '' ? undefined : parseInt(filter.year)

  const sortBy = SortBy.filter(s => s.code === filter.order)[0] ?? SortBy[0]

  const filterCount = countTranFilters(filter)

  const transactionListUrl = TranPaths.list + buildQuery({...filter, page: 1}, true)

  function navigate(filter: TransactionFilterForm) {
    SessionStorage.setCurrentTransactionsFilter({...filter, view: 'list'})

    history.replace(`${location.pathname}?${buildQueryParams<TransactionFilterForm>(filter, true)}&${uniqueId('_unq=')}`)
  }

  const onSearchGroupChange = (newSearchGroup: SearchGroupOptionsT) => {
    const newFilter: TransactionFilterForm = {
      ...filter,
      searchGroup: newSearchGroup,
    }
    navigate(newFilter)
  }

  const resetSearch = () => {
    const emptyFilter = qsToTranForm(new URLSearchParams())
    navigate(emptyFilter)
  }

  const onSortChange = (newVal: SortByType) => {
    const newFilter: TransactionFilterForm = {
      ...filter, order: newVal.code,
      sortMode: 'desc'
    }
    navigate(newFilter)
  }


  const onYearChange = (yr: number | undefined) => {
    const newFilter: TransactionFilterForm = {
      ...filter,
      year: typeof yr === 'undefined' ? '' : `${yr}`
    }
    navigate(newFilter)
  }


  return (
    <>
      {(subTab === TranListSubTab.Transactions || subTab === TranListSubTab.Related) &&
        <TransactionListSubNav
          filterCount={filterCount}
          subTab={subTab}
          searchGroup={filter.searchGroup}
          onSearchGroupChange={onSearchGroupChange}
          year={year}
          onYearChange={onYearChange}
          sortBy={sortBy}
          onSortChange={onSortChange}
          reset={resetSearch}
          transactionListUrl={transactionListUrl}
        />
      }


      <LoadingComponent loading={loading} error={error}>
        <>
          {subTab === TranListSubTab.Transactions
          && <TransactionTable onSelect={setPreviewTranId} list={tranList} previewId={previewTranId} />}
          {subTab === TranListSubTab.Related
          && <TransactionEntityTable onSelect={setPreviewEntityId} entities={entityList} previewId={previewEntityId} />}
          {subTab === TranListSubTab.Filters
          && <TransactionFilterEditor origFilter={filter} showMapSubNav={false} />}
        </>
      </LoadingComponent>

    </>
  )
}
