import {CsvDownloadAcceptTermsModal} from 'components/CsvDownloadAcceptTermsModal';
import {useRef, useState} from 'react';
import {useAppContext} from 'context';
import { GlobalSearchApiParams, TransactionSearchResults } from 'apis';
import { EntityRelatedTranQueryReq } from 'apis/TransactionsApi';
import { TranFilterQueryReq } from 'models';

export function CsvDownload(
  {
    params,
    filename,
    searchCSV
  } :{
    params: any
    filename: string
    searchCSV: (params: any) => Promise<string>
  }
) : JSX.Element {

  const { user } = useAppContext()

  const [showTerms, setShowTerms] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [progress, setProgress] = useState(0)

  const downloadLink = useRef<HTMLAnchorElement>(null);

  function downloadData(filename:string, content:string) {
    const blob = new Blob([content], {type: 'text/csv'});
    const el = window.document.createElement('a');
    el.href = window.URL.createObjectURL(blob);
    el.download = filename;
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
  }

  async function startDownload() {
    const per_page = 500;
    let page = 1;
    try {
      let results = ''
      setDownloading(true)
      setProgress(0)
      while(true) {
        let batchParams = {
          ...params,
          page,
          per_page
        }
        if (page != 1) {
          batchParams.no_headers = '1'
        }
        let batch = await searchCSV(batchParams)
        results = results + batch;
        if (batch.split(/\r\n|\r|\n/).length < per_page) {
          break;
        }
        setProgress(page * per_page);
        page = page + 1;
      }
      downloadData(`${filename}.csv`, results)
      setDownloading(false);
    } catch(e) {
      console.log(e)
      alert("Error downloading")
      setDownloading(false)
    }
  }

  function onCloseTerms(triggerDownload: boolean) {
    setShowTerms(false)
    if (triggerDownload && downloadLink.current) {
      downloadLink.current.click()
    }
  }

  const linkText = "CSV Download"
  const downloadButton = (
    <button onClick={startDownload}
      className="btn btn-sm btn-outline-primary mx-1"
      style={{ 
        marginTop: '-2px',
        display: user.accept_csv_terms ? 'inline' : 'none'
      }}
    >
      {linkText}
    </button>
  )
  const downloadingBar = (
    <div className="progress ml-2" style={{display: 'inline-flex', height: '22px'}}>
      <div className="progress-bar progress-bar-striped progress-bar-animated" 
        style={{width: '100%'}}
      >
        <span className="p-5">Downloading {progress}</span>
      </div>
    </div>
  )

  return (
    <>
      {user.accept_csv_terms && 
        <>
          {downloading && downloadingBar}
          {!downloading && downloadButton}
        </>
      }
      {!user.accept_csv_terms &&
        <a href='#' onClick={(e) => { setShowTerms(true); e.preventDefault(); return false}}>
          {linkText}
        </a>
      }
      { showTerms &&
        <CsvDownloadAcceptTermsModal onClose={onCloseTerms} />
      }
    </>
  )
}
