import {Api} from './Api'
import {
  Entity,
  Entities,
  EntityRiskDesignation,
  EntityRiskDesignations,
  EntityRiskEventsRes,
  ErtFilterQueryReq,
  EntityRiskResult,
  EntityRelatedRisksRes,
} from 'models'

const URLS = {
  filters: '/entity_risk_tracker/filters',
  entities: '/entity_risk_tracker',
  risks: '/entity_risk_tracker/risks',
  events: '/entity_risk_tracker/events',
  entityEventsCsv: '/entity_risk_tracker/events.csv',
  all_risks: '/entity_risk_tracker/risks/all',
  entity: '/entity_risk_tracker/:id',
  entity_risks: '/entity_risk_tracker/:id/risks',
  entity_risk_events: '/entity_risk_tracker/:id/risk_events',
  entity_related_risk_events: '/entity_risk_tracker/:id/related_risk_events',
  entity_related_risks: '/entity_risk_tracker/:id/related_risks2',
}




export interface EntityRisksResults {
  [key: string]: EntityRiskDesignation
}


// TODO -- Better error handling for API.  Make this consistent across all APIs?
export class EntityRiskTrackerApi extends Api {

  async filters(): Promise<EntityRiskResult[]> {
    let filters = (await this.get<EntityRiskResult[]>(URLS.filters)).data
    return filters
  }
  async search(params: ErtFilterQueryReq): Promise<Entities> {
    let entities = (await this.get<Entities>(URLS.entities, params)).data
    return entities
  }

  async risks(): Promise<EntityRiskResult[]> {
    const risks = (await this.get<EntityRiskResult[]>(URLS.risks)).data
    return risks
  }

  async events(params: ErtFilterQueryReq): Promise<EntityRiskEventsRes> {
    let events = (await this.get<EntityRiskEventsRes>(URLS.events, params)).data
    return events
  }
  downloadEventsLink(params: ErtFilterQueryReq): string {
    return this.requestUrl(URLS.entityEventsCsv, params)
  }
  downloadEntityEventsLink(entity_id: number): string {
    return this.requestUrl(URLS.entityEventsCsv, { 
      entity_ids: [entity_id]
    })
  }

  async searchCSV({
    entity_id,
    page,
    per_page
  }:{
    entity_id: number
    page: number
    per_page: number
  }): Promise<string> {
    return (await this.get<string>(URLS.entityEventsCsv, {
      entity_id,
      page,
      per_page,
      responseType: 'text'
    })).data
  }

  async entity(id: number): Promise<Entity> {
    const url = URLS.entity.replace(":id", id+"")
    let entity = (await this.get<Entity>(url)).data
    return entity
  }
  async entity_risks(id: number, page?: number, per_page?: number): Promise<EntityRiskDesignations> {
    const pager = [(page ? 'page='+page : ''), (per_page ? 'per_page='+per_page : '')].join('&')
    const url = URLS.entity_risks.replace(":id", id+"")+(pager.length ? '?'+pager : '')
    let entity_risks = (await this.get<EntityRiskDesignations>(url)).data
    return entity_risks
  }
  async entity_risk_events(id: number, page?: number, per_page?: number): Promise<EntityRiskEventsRes> {
    const pager = [(page ? 'page='+page : ''), (per_page ? 'per_page='+per_page : '')].join('&')
    const url = URLS.entity_risk_events.replace(":id", id+"")+(pager.length ? '?'+pager : '')
    let entity_risk_events = (await this.get<EntityRiskEventsRes>(url)).data
    return entity_risk_events
  }
  async entity_related_risk_events(id: number, page?: number, per_page?: number): Promise<EntityRiskEventsRes> {
    const pager = [(page ? 'page='+page : ''), (per_page ? 'per_page='+per_page : '')].join('&')
    const url = URLS.entity_related_risk_events.replace(":id", id+"")+(pager.length ? '?'+pager : '')
    let entity_risk_events = (await this.get<EntityRiskEventsRes>(url)).data
    return entity_risk_events
  }
  async entity_related_risks(id: number, page?: number, per_page?: number): Promise<EntityRelatedRisksRes> {
    const pager = [(page ? 'page='+page : ''), (per_page ? 'per_page='+per_page : '')].join('&')
    const url = URLS.entity_related_risks.replace(":id", id+"")+(pager.length ? '?'+pager : '')
    let entity_risks = (await this.get<EntityRelatedRisksRes>(url)).data
    return entity_risks
  }
}
